import React from 'react'
import Helmet from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import ReactHtmlParser from 'react-html-parser'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import SEO from '@/helpers/SEOHelper.js'

import Layout from '@/components/Layout'

export const PrivacyPageTemplate = ({ image, hero, body }) => {
  return (
    <>
      <GatsbyImage
        image={getImage(image)}
        className='background img-grey op-5'
      />
      <section className='page'>
        <section className='section section--large pb-16 mb-32'>
          <div className='container-sm'>
            <div className='section__title section__title--center text-center'>
              <h2 className='title-6 text-accent'>{hero.subheading}</h2>
              <h1 className='mb-32'>{hero.heading}</h1>
              <ReactMarkdown className='title-5' children={hero.description} />
            </div>
          </div>
        </section>
        <section className='section'>
          <div className='container-sm'>
            <div className='page__content'>{ReactHtmlParser(body)}</div>
          </div>
        </section>
      </section>
    </>
  )
}

const PrivacyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const seo = frontmatter.seo
  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo?.image?.publicURL)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>
      <PrivacyPageTemplate
        image={frontmatter.image}
        hero={frontmatter.hero}
        body={data.markdownRemark.html}
      />
    </Layout>
  )
}

export default PrivacyPage

export const pageQuery = graphql`
  query PrivacyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 45
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hero {
          subheading
          heading
          description
        }
        seo {
          title
          description
          image {
            publicURL
          }
        }
      }
      html
    }
  }
`
